import React from 'react'
import { graphql } from 'gatsby'
import { BLOCKS } from '@contentful/rich-text-types'
import {
  documentToReactComponents,
  documentToReactAboutComponentsOptions,
  documentToReactSpecialComponentsOptions
} from '../components/contentful'

import Layout from '../components/layout'
import SEO from '../components/seo'

class AboutPage extends React.Component {
  render () {
    const page = this.props.data.contentfulPage
    const document = page.pageContent.json

    const aboutBlocks = []

    // @todo use .shift() here on the array
    let blockIndex = -1
    document.content.forEach(content => {
      if (content.nodeType === BLOCKS.EMBEDDED_ASSET) {
        blockIndex = blockIndex + 1
        aboutBlocks[blockIndex] = []
      }

      aboutBlocks[blockIndex].push(content)
    })

    const universityContentElements = documentToReactComponents(
      {
        ...document,
        content: aboutBlocks[2]
      },
      documentToReactAboutComponentsOptions
    )

    const logoBlock = []
    const specialBlock = []
    let searchString = 'Special Acknowledgments'
    if (this.props.pageContext.locale === 'en-US') {
      searchString = 'Special Acknowledgments'
    } else if (this.props.pageContext.locale === 'es-MX') {
      searchString = 'Reconicimientos Especiales'
    }

    const specialIndex = aboutBlocks[1].findIndex(bl =>
      JSON.stringify(bl).includes(searchString)
    )
    if (specialIndex > 0) {
      aboutBlocks[1].forEach((bl, index) => {
        if (index < specialIndex) {
          logoBlock.push(bl)
        } else {
          specialBlock.push(bl)
        }
      })
    }

    const companyContentElements = documentToReactComponents(
      {
        ...document,
        content: logoBlock
      },
      documentToReactAboutComponentsOptions
    )

    const specialContentElements = documentToReactComponents(
      {
        ...document,
        content: specialBlock
      },
      documentToReactSpecialComponentsOptions
    )

    const defaultHero =
      '//images.ctfassets.net/pjx1km6eothd/4O64wdv3fDQk6RF1L1qf34/04505acfa008ba829f987fbdc57813d7/Dad-baby-mom-hero.jpg'
    const defautlHeroAltText = 'Mom and dad reading to baby'

    const heroImageUrl = aboutBlocks[0][0].data.target.fields
      ? aboutBlocks[0][0].data.target.fields.file['en-US'].url
      : defaultHero
    // remove "hero" and replace "-" with a space
    const heroImageTitle = aboutBlocks[0][0].data.target.fields
      ? aboutBlocks[0][0].data.target.fields.title['en-US']
        .replace(/hero/g, '')
        .replace(/-/g, ' ')
      : defautlHeroAltText

    return (
      <Layout
        location={this.props.location}
        locale={this.props.pageContext.locale}
      >
        <SEO title='About' />
        <div className='top-0 left-0 w-full -mt-32 relative'>
          <img
            src={heroImageUrl}
            alt={heroImageTitle}
            longdesc='#heroLongDesc'
            className='w-full h-hero ie-object-cover object-top'
          />
          <div id='heroLongDesc' name='heroLongDesc' className='hidden'>
            {heroImageTitle}
          </div>
          <div className='absolute top-0 w-full h-full bg-multiply-grey' />
        </div>
        <div className='main-content container px-4 mx-auto top-0 relative'>
          <h1 className='text-5xl text-white font-bold -mt-64 lg:-mt-56 mb-64 px-0 lg:px-4'>
            {aboutBlocks[0][1].content[0].value}
          </h1>
          <div className='flex justify-around pt-16 mb-10 flex-wrap lg:flex-no-wrap leading-tight'>
            <div className='w-full lg:w-logo relative bg-gray'>
              <div className='absolute min-h-1/4 bg-gray w-full z-0 -mt-8 lg:mt-0 h-32 lg:h-24 bg-turqouise' />
              <div className='w-full -mt-24 py-4 px-8 relative'>
                {companyContentElements}
              </div>
              <div className='w-full py-4 px-8 relative pr-0'>
                {specialContentElements}
              </div>
            </div>
            <div className='w-full lg:w-university relative mt-20 lg:mt-0 bg-gray'>
              <div className='absolute lg:absolute min-h-1/4 bg-gray w-full z-0 mt-0 lg:mt-0 h-40 lg:h-24 bg-turqouise' />
              <div className='w-full mt-0 lg:-mt-28 py-4 px-8 relative pr-0'>
                {universityContentElements}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AboutPage

export const fluidHeroImage = graphql`
  fragment fluidHeroImage on File {
    childImageSharp {
      fluid(
        maxWidth: 2400
        traceSVG: { background: "#fffaf7", color: "#ffefe6" }
      ) {
        ...GatsbyImageSharpFluid_withWebp
        tracedSVG
      }
    }
  }
`

export const aboutPageQuery = graphql`
  query aboutPageQuery($locale: String!) {
    contentfulPage(slug: { eq: "about" }, node_locale: { eq: $locale }) {
      id
      title
      pageContent {
        json
      }
    }
  }
`
